<template>
    <section class="w-full">
        <header-register :showButtonView="false">Ensaio</header-register>
        <main class="w-full">
            <ManageEnsaioCreate/>
        </main>
        </section>
  </template>
  
<script type="module">

import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageEnsaioCreate from "@/components/groupProducts/ensaio/components/create/ManageEnsaioCreate.vue";


export default {
    name: "ProvidersEdit",
    components: {
    HeaderRegister,
    ManageEnsaioCreate
},
};

</script>