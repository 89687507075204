<template>
    <ValidationObserver ref="observer" v-slot="{ validate }">
        <b-form @submit.stop.prevent="validate().then(CreateEnsaio)">
            <div class="w-full flex flex-col justify-between">
                <GeneralEnsaioInformation v-model="viewModel" class="mt-4"/>
            </div>
            <div class="flex mt-3">
                <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
            </div>
            <footer class="w-full flex items-center justify-start gap-5 my-10">
            <Button
                class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
                buttonText="Confirmar"
                type="submit"
            />
            <Button
                class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
                buttonText="Cancelar"
                :onClick="redirectUrl"
            />
            </footer>
            <Modal
            v-if="modalIsOpen"
            :modalIsOpen.sync="modalIsOpen"
            :closeModal="closeModal"
            :modalText="$t('commons.modalTextCreate')"
            />

            <Modal
            v-if="modalError"
            :modalIsOpen.sync="modalError"
            :closeModal="closeModal"
            :modalText="$t('commons.modalErrorCreate')"
            />
            <Modal
            v-if="modalErroSalvar"
            :modalIsOpen.sync="modalErroSalvar"
            :closeModal="closeModal"
            :modalText="$t('commons.modalErroSalvar')"
            />
        </b-form>
    </ValidationObserver>
</template>

<script>
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

import GeneralEnsaioInformation from "./GeneralEnsaioInformation.vue";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";

export default {
    name: 'ManageEnsaioCreate',
    components: {
    BForm,
    ValidationObserver,
    GeneralEnsaioInformation,
    Button,
    Modal,
},
    data() {
        return {
            modalIsOpen: false,
            modalError: false,
            modalErrorMessage: null,
            modalErroSalvar:false,
            viewModel: {
                nomeEnsaio: null,
                descricaoEnsaio: null,
                farmacopeiaId: null,
                nomeFarmacopeia: null,
            }
        }
    },
    methods: {
        redirectUrl() {
            return this.$router.push("/groupProducts/ensaio");
        },
        openModal() {
            this.modalIsOpen = true;
        },
        closeModal(event) {
            if (event.target === event.currentTarget) {
                this.modalIsOpen = false;
                this.modalError = false;
                this.modalErroSalvar = false;
                window.scrollTo({
                top: 0,
                behavior: "smooth",
                });
            }
        },
        async CreateEnsaio () { 
            const isValid = await this.$refs.observer.validate();
        
            if (!isValid) {
                    this.modalError = true;
                    return
                }
                this.$http({
                url: "/ensaio/novo",
                data: this.viewModel,
                method: "POST"
            }).then(() => {
                this.modalIsOpen = true;
                setTimeout(() => this.$router.push("/groupProducts/ensaio"), 2000);
            })
                .catch(() => this.modalErroSalvar = true)
        },
    },
}
</script>